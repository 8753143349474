import { useEffect, useRef, useState, useLayoutEffect } from 'react'
import { Paper, Grid, Typography, Link, Alert, AlertTitle } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import PageTitle from '../common/PageTitle'
import TextInput from '../common/TextInput'
import PhoneNumberInput from '../common/PhoneNumberInput'
import ButtonWithProgress from '../common/ButtonWithProgress'
import CheckboxInput from '../common/CheckboxInput'

import RegisterPageLocationFieldset from './RegisterPageLocationFieldset'

import ReCAPTCHA from 'react-google-recaptcha'

import authService from '../../service/authService'
import intl from '../../helper/intl'
import validate from '../../helper/validate'
import maps from '../../helper/maps'
import broadcast from '../../helper/broadcast'

import { useSelector, useDispatch } from 'react-redux'
import { registerStart, registerSuccess, registerFailure, registerCredentialsChange } from '../../actions/authAction'
import { openAppSnackbar } from '../../actions/notificationsAction'

import useStyles from './RegisterPage.styles'
import utils from '../../helper/utils'
import BasicSnackbar from '../common/BasicSnackbar'
import NativeSelectInput from '../common/NativeSelectInput'
import AutoFillAwareTextInput from '../common/AutofillInput/AutoFillAwareTextInput'

const RegisterPage = () => {
  const companyData = useSelector((state) => state.auth.companyData)
  const registerLoading = useSelector((state) => state.auth.registerLoading)
  const registerCredentials = useSelector((state) => state.auth.registerCredentials)
  const availableLanguages = useSelector((state) => state.intl.availableLanguages)
  const urlBasename = useSelector((state) => state.intl.urlBasename)
  const dispatch = useDispatch()
  const location = useLocation()
  const classes = useStyles()
  const captchaRef = useRef(null)
  const [captchaToken, setCaptchaToken] = useState(null)
  const [successSnackbarIsOpen, setSuccessSnackbarIsOpen] = useState(false)
  const [showSuccessSnackbarDescription, setShowSuccessSnackbarDescription] = useState(false)

  const availableLanguagesTranslated = []
  for (let i = 0; i < availableLanguages.length; i++) {
    const language = availableLanguages[i]
    availableLanguagesTranslated.push({
      name: intl.translateToLang('app_header__language_select_current', null, language.value.toLowerCase(), language.name) || language.name,
      value: language.value
    })
  }

  const mapId = 'register-page-location-fieldset--map'

  useEffect(() => {
    document.title = intl.translate('register_page__title') + ' | ' + (companyData.portal_title || intl.translate('app_header__title'))

    if (companyData.name === 'Longueuil' && !registerCredentials.countryCode) {
      onRegisterCredentialsChange('countryCode', 'CA') // Initialize countryCode = +1 for Longueuil
    }
  }, [companyData])

  const getPhoneNumberError = (countryCode) => {
    if (countryCode === 'CA' || countryCode === 'US') {
      return intl.translate('general__invalid_north_american_phone_number')
    }

    return intl.translate('general__invalid_phone_number')
  }

  const onRegisterCredentialsChange = (paramName, paramValue) => {
    const newRegisterCredentials = { ...registerCredentials }
    newRegisterCredentials[paramName] = paramValue
    dispatch(registerCredentialsChange({ credentials: newRegisterCredentials }))
  }

  const onRegisterFormSubmit = (event) => {
    event.preventDefault()

    dispatch(registerStart())

    const formError = getRegisterFormError()
    if (formError) {
      dispatch(registerFailure({ error: formError }))
      dispatch(openAppSnackbar({ message: formError }))
      return false
    }

    const credentialsWithCaptcha = { ...registerCredentials }
    if (captchaToken) {
      credentialsWithCaptcha.recaptcha_token = captchaToken
      setCaptchaToken(null)
    }

    if (captchaRef.current) {
      captchaRef.current.reset()
    }

    authService
      .register(credentialsWithCaptcha)
      .then((result) => {
        if (location.pathname.includes('/registerpublic')) {
          clearFormAndDisplaySucessMessage()
        } else {
          dispatch(
            registerSuccess({
              accountId: result.data.id,
              accessToken: result.data.accessToken,
              refreshToken: result.data.refreshToken
            })
          )
        }
      })
      .catch((error) => {
        let message = intl.getServerError(error.response.data) || intl.translate('register_page__register_error')
        if (error.response.data.internalCode === 5711) {
          message = intl.translate("register_page__error_5711")
        }
        dispatch(registerFailure({ error: intl.translate('register_page__register_error') }))
        dispatch(
          openAppSnackbar({
            message
          })
        )
      })
  }

  const clearFormAndDisplaySucessMessage = () => {
    if (companyData.portal_email_register_confirmation_message && companyData.portal_sms_register_confirmation_message) {
      setShowSuccessSnackbarDescription(true)
    } else if (companyData.portal_email_register_confirmation_message && registerCredentials.email) {
      setShowSuccessSnackbarDescription(true)
    } else if (companyData.portal_sms_register_confirmation_message && registerCredentials.phoneNumber && registerCredentials.countryCode) {
      setShowSuccessSnackbarDescription(true)
    } else {
      setShowSuccessSnackbarDescription(false)
    }

    dispatch(
      registerCredentialsChange({
        credentials: {
          firstName: '',
          lastName: '',
          language: '',
          email: '',
          countryCode: '',
          phoneNumber: '',
          password: '',
          passwordConfirm: '',
          phoneNumberSms: true,
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipCode: '',
          state: '',
          country: '',
          longitude: '',
          latitude: ''
        }
      })
    )

    broadcast.trigger('text-input-register-page--first-name', { isDirty: false })
    broadcast.trigger('text-input-register-page--last-name', { isDirty: false })
    broadcast.trigger('text-input-register-page--email', { isDirty: false })
    broadcast.trigger('text-input-register-page--phone-number', { isDirty: false })
    broadcast.trigger('text-input-register-page--password', { isDirty: false })
    broadcast.trigger('text-input-register-page--password-confirm', { isDirty: false })
    broadcast.trigger('text-input-register-page--address-line-1', { isDirty: false })
    broadcast.trigger('text-input-register-page--address-line-2', { isDirty: false })
    broadcast.trigger('text-input-register-page--city', { isDirty: false })
    broadcast.trigger('text-input-register-page--zip-code', { isDirty: false })
    broadcast.trigger('text-input-register-page--state', { isDirty: false })
    broadcast.trigger('text-input-register-page--country', { isDirty: false })

    maps.removeMarkerFromMap(mapId)
    maps.zoomToDisplayAllCoordinates(mapId, companyData.bounds)

    window.scrollTo({ top: 0, behavior: 'smooth' })

    setSuccessSnackbarIsOpen(true)

    dispatch(registerSuccess({ accountId: '', accessToken: '', refreshToken: '' }))
  }

  const getRegisterFormError = () => {
    if (companyData.portal_registration_name_mandatory && !registerCredentials.firstName.trim()) {
      return intl.translate('account_individuals_page__empty_first_name_error')
    } else if (companyData.portal_registration_name_mandatory && !registerCredentials.lastName.trim()) {
      return intl.translate('account_individuals_page__empty_last_name_error')
    } else if (!registerCredentials.phoneNumber && !validate.isValidEmail(registerCredentials.email)) {
      return intl.translate('general__invalid_email')
    } else if (!registerCredentials.email && !registerCredentials.countryCode) {
      return intl.translate('general__empty_phone_prefix_error')
    } else if (
      registerCredentials.phoneNumber !== '' &&
      !validate.isValidPhoneNumber(registerCredentials.phoneNumber, { countryCode: registerCredentials.countryCode })
    ) {
      return getPhoneNumberError(registerCredentials.countryCode)
    }

    const passwordIsValid = validate.isValidPassword(registerCredentials.password)
    const passwordConfirmIsValid = registerCredentials.password === registerCredentials.passwordConfirm
    if (!passwordIsValid) {
      return validate.getInvalidPasswordError()
    } else if (!passwordConfirmIsValid) {
      return intl.translate('general__invalid_password_confirm')
    }

    return ''
  }

  const GOOGLE_CAPTCHA_KEY = window.__env__ ? window.__env__.GOOGLE_CAPTCHA_KEY || '' : ''

  return (
    <>
      <Paper className="_centered_container_">
        <PageTitle>{intl.translate('register_page__title')}</PageTitle>
        <form autoComplete="off" onSubmit={onRegisterFormSubmit}>
          <Grid container spacing={2}>
            {utils.checkPermission('register_email_view') && utils.checkPermission('register_phone_number_view') && (
              <Grid item xs={12}>
                <Typography variant="body1" mt={2} mb={2}>
                  {intl.translate('register_page__email_phone_clarification_1')}
                </Typography>
                <Typography variant="body1">{intl.translate('register_page__email_phone_clarification_2')}</Typography>
              </Grid>
            )}
            {companyData.portal_registration_name && (
              <>
                <Grid item sm={6} xs={12}>
                  <TextInput
                    id="register-page--first-name"
                    label={intl.translate('account_details_page__first_name')}
                    listenToEvents
                    disabled={registerLoading}
                    required={companyData.portal_registration_name_mandatory === true}
                    value={registerCredentials.firstName}
                    onChange={(event) => onRegisterCredentialsChange('firstName', event.target.value)}
                    isValid={(value) => (companyData.portal_registration_name_mandatory ? !!value.trim() : true)}
                    errorMessage={intl.translate('general__empty_field_error')}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInput
                    id="register-page--last-name"
                    label={intl.translate('account_details_page__last_name')}
                    listenToEvents
                    disabled={registerLoading}
                    required={companyData.portal_registration_name_mandatory === true}
                    value={registerCredentials.lastName}
                    onChange={(event) => onRegisterCredentialsChange('lastName', event.target.value)}
                    isValid={(value) => (companyData.portal_registration_name_mandatory ? !!value.trim() : true)}
                    errorMessage={intl.translate('general__empty_field_error')}
                  />
                </Grid>
              </>
            )}

            {companyData.portal_registration_language && (
              <Grid item xs={12}>
                <NativeSelectInput
                  id="register-page--language"
                  label={intl.translate('account_details_page__language')}
                  disabled={registerLoading}
                  required={companyData.portal_registration_language_mandatory === true}
                  variant="outlined"
                  fullWidth={true}
                  margin="normal"
                  value={registerCredentials.language}
                  options={availableLanguagesTranslated}
                  onChange={(event) => onRegisterCredentialsChange('language', event.target.value)}
                />
              </Grid>
            )}

            {utils.checkPermission('register_email_view') && (
              <Grid item xs={12}>
                <AutoFillAwareTextInput
                  id="register-page--email"
                  label={intl.translate('general__email_label')}
                  listenToEvents
                  disabled={registerLoading}
                  value={registerCredentials.email}
                  onChange={(event) => onRegisterCredentialsChange('email', event.target.value)}
                  isValid={(value) => (value ? validate.isValidEmail(value) : true)}
                  errorMessage={intl.translate('general__invalid_email')}
                />
              </Grid>
            )}

            {utils.checkPermission('register_phone_number_view') && (
              <Grid item xs={12}>
                <PhoneNumberInput
                  id="register-page--phone-number"
                  label={intl.translate('general__phone_number_label')}
                  listenToEvents
                  disabled={registerLoading}
                  value={registerCredentials.phoneNumber}
                  countryCodeValue={registerCredentials.countryCode}
                  onPhoneNumberChange={(event) => onRegisterCredentialsChange('phoneNumber', event.target.value)}
                  onCountryCodeChange={(event) => onRegisterCredentialsChange('countryCode', event.target.value)}
                  isValid={(value) => (value ? validate.isValidPhoneNumber(value, { countryCode: registerCredentials.countryCode }) : true)}
                />

                <CheckboxInput
                  id="register-page-phone-number-sms"
                  label={intl.translate('general__phone_number_sms')}
                  disabled={registerLoading}
                  checked={registerCredentials.phoneNumberSms}
                  onChange={(event) => onRegisterCredentialsChange('phoneNumberSms', event.target.checked)}
                />
              </Grid>
            )}

            {companyData.location_in_reg && (
              <Grid item xs={12}>
                <RegisterPageLocationFieldset mapId={mapId} />
              </Grid>
            )}

            <Grid item xs={12}>
              <AutoFillAwareTextInput
                id="register-page--password"
                type="password"
                label={intl.translate('general__password_label')}
                listenToEvents
                disabled={registerLoading}
                value={registerCredentials.password}
                onChange={(event) => onRegisterCredentialsChange('password', event.target.value)}
                isValid={validate.isValidPassword}
                errorMessage={validate.getInvalidPasswordError()}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <AutoFillAwareTextInput
                id="register-page--password-confirm"
                type="password"
                label={intl.translate('general__password_confirm_label')}
                listenToEvents
                disabled={registerLoading}
                value={registerCredentials.passwordConfirm}
                onChange={(event) => onRegisterCredentialsChange('passwordConfirm', event.target.value)}
                isValid={(value) => value === registerCredentials.password}
                errorMessage={intl.translate('general__invalid_password_confirm')}
                required
              />
            </Grid>
            {utils.checkPermission('register_recaptcha') && (
              <Grid item xs={12}>
                <ReCAPTCHA
                  className={classes['google-recaptcha']}
                  sitekey={GOOGLE_CAPTCHA_KEY || '-' /* sitekey is mandatory */}
                  ref={captchaRef}
                  onChange={(token) => setCaptchaToken(token)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <div className="_centered_btn_container_">
                <ButtonWithProgress
                  type="submit"
                  id="register-page--submit-btn"
                  disabled={utils.checkPermission('register_recaptcha') ? !captchaToken : false}
                  showProgress={registerLoading}
                  variant="contained"
                  color={window.app.theme.palette.button ? 'button' : 'primary'}
                  size="large"
                >
                  {intl.translate('register_page__submit_btn')}
                </ButtonWithProgress>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes['link-to-login']}
                align="center"
                variant="body1"
                color={window.app.theme.palette.button ? 'link.main' : 'primary'}
              >
                <span>{intl.translate('register_page__prompt_to_login')} </span>
                <Link component={RouterLink} to={urlBasename} color="inherit">
                  {intl.translate('register_page__link_to_login')}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <BasicSnackbar
        isOpen={successSnackbarIsOpen}
        duration={6000}
        onClose={() => {
          setSuccessSnackbarIsOpen(false)
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          elevation={6}
          onClose={() => {
            setSuccessSnackbarIsOpen(false)
          }}
        >
          <AlertTitle sx={showSuccessSnackbarDescription ? {} : { marginTop: 0, marginBottom: 0 }}>
            {intl.translate('register_page__success_message_title')}
          </AlertTitle>
          {showSuccessSnackbarDescription && intl.translate('register_page__success_message_description')}
        </Alert>
      </BasicSnackbar>
    </>
  )
}

export default RegisterPage
