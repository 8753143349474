// polyfills
import './helper/polyfills'

// Auth service
import authService from './service/authService'

// React
import { createRoot } from 'react-dom/client'

// Redux
import { Provider } from 'react-redux'
import configureStore from './store'

// React router
import { BrowserRouter } from 'react-router-dom'
// App component (root)
import App from './components/App'

import * as serviceWorker from './serviceWorker'

import utils from './helper/utils'

import './index.scss'
// import './config/axiosConfig'

export const store = configureStore()

const renderApplication = () => {
  const container = document.getElementById('root')
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <BrowserRouter basename={window.__env__ ? window.__env__.PUBLIC_PATH : undefined}>
        <App />
      </BrowserRouter>
    </Provider>
  )
  const loadingRoot = document.getElementById('loading-root')
  loadingRoot.classList.add('hidden')
}
authService
  .getCompanyData()
  .then((result) => {
    // comprobar endpoint para prefijo
    const availableLanguages = []
    const numLanguages = result.data.lang ? result.data.lang.length : 0
    for (let i = 0; i < numLanguages; i++) {
      availableLanguages.push({
        name: result.data.lang[i].language,
        value: result.data.lang[i].code
      })
    }
    window.availableLanguages = availableLanguages
    utils.setPermissions(result.data.permissions)
    window.companyData = result.data || {}

    const themeScript = document.createElement('script')
    themeScript.onload = () => {
      renderApplication()
    }
    themeScript.onerror = () => {
      renderApplication()
    }

    themeScript.src = utils.generateAWSUrl('theme.js')

    document.head.appendChild(themeScript)
  })
  .catch((error) => {
    renderApplication()
    console.error(error)
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
